
.facebook{
    margin-top: 0.55%;
    margin-left: 0.8%;
    margin-right: 1%;
  }
  
  .fejs{ 
    font-size: large;
    color: #3b5998;
  }

  .fejs2{
    color: white;
    font-size: large;
    margin-right: -15%;
    margin-left: -2%;
    margin-bottom: 10%;
    transition: color ease-in-out 0.3s;
  }

  .fejs2:hover{
    color: #40E0D0;
  }