.nikola {
  height: 700px;
  margin: auto;
}

.container2 {
  position: relative;
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  -webkit-filter: invert(100%);
  filter: invert(100%);
  padding: 0%;
  margin: 0 -15%;
}

.carousel-control-next-icon:hover {
  cursor: pointer;
}

.carousel-control-prev-icon:hover {
  cursor: pointer;
}

.komentar > img {
  width: 80% !important;
  margin: auto;
}

.kolonija.carousel-inner {
  padding: 0 10%;
}

.view.restoran {
  width: 66%;
  margin: auto;
}

.restoran > img {
  height: 100%;
  margin: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.strelica-lijevo {
  margin-left: 1.5%;
}

.strelica-desno {
  margin-right: 1.5%;
}

@media only screen and (max-width: 1063px) {
  .view.restoran {
    width: 100%;
    height: 250px;
  }

  .carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    -webkit-filter: invert(100%);
    filter: invert(100%);
    padding: 0%;
    margin: 0 -5.5%;
  }

  .strelica-lijevo {
    margin-left: 1%;
    -webkit-filter: invert(0);
    filter: invert(0);
  }
  .strelica-desno {
    margin-right: 1%;
    -webkit-filter: invert(0);
    filter: invert(0);
  }
  .nikola {
    height: 600px !important;
  }
}

.min-vh-100 {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@-webkit-keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
