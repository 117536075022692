html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

._3LDgJ {
  border-radius: 0.2rem !important;
  border-color: gray !important;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 80vh;
  margin-top: 0%;
  opacity: 1;
  -webkit-transition: opacity ease-in 2s;
  -moz-transition: opacity ease-in 2s;
  -o-transition: opacity ease-in 2s;
  transition: opacity ease-out 2s;
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 5000ms linear 5000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 5000ms linear;
}

.fade-exit-done {
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .loader {
    left: 43%;
  }
}

.loader-hidden {
  opacity: 0;
}
