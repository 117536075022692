.carousel-status{
    display: none;
}

.thumb-slika > img{
    
    object-fit: contain;
    height: auto;   
    overflow: hidden;
}

.thumbs > .selected{
    border-color: rgb(160, 139, 80) !important;
}

.thumb-slika{
    cursor: zoom-in;
    pointer-events: none;
    width: 90% !important;
    margin: auto;
    margin-top: 5%;
}

.thumb-slika:hover{
    filter: brightness(75%);
}

.thumbs.animated{
    transform: none !important;
}

.thumbs > li:hover{
    border-color: rgb(160, 139, 80) !important;
}

@media only screen and (max-width: 744px){
    .thumb-slika{
        width: 97% !important;
    }
}

@media only screen and (max-width: 1535px){
    .thumb-slika{
        cursor: default;
    }
    .thumb-slika:hover{
        filter: brightness(100%);
    }
}
