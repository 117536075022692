.book{
    width: 7%;
    border: none;
    background-color: #9dad7f;
    background-size: auto;
    color: white;
    font-size: medium;
    font-weight: 600;
    margin-right: 3%;
    font-family: 'helvetica';
    height: 102%;
  }

  .book2{
    width: 50%;
    height: 150%;
    border: none;
    background-color: #9dad7f;
    background-size: auto;
    color: white;
    font-size: medium;
    font-weight: 600;
    margin-left: 7%;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: -2.5%;
  }

  .kontakt-dugme{
    margin-top: 1%;
    width: 160px;
    border: none;
    background-color: #9dad7f;
    background-size: auto;
    color: white;
    font-size: 160%;
    font-weight: 600;
    margin-left: 30%;
    font-family: 'helvetica';
    height: 50px;
    display: block;
    position: relative;
  }

  .restoran-dugme{
    margin-top: 2.5%;
    width: 490px;
    border: none;
    background-color: #9dad7f;
    background-size: auto;
    color: white;
    font-size: 200%;
    font-weight: 600;
    margin-left: 15%;
    font-family: 'helvetica';
    height: 50px;
    display: block;
    position: relative;
  }

  .smjestaj-dugme{
      margin-top: 2.5%;
      width: 70%;
      border: none;
      background-color: #9dad7f;
      background-size: auto;
      color: white;
      font-size: 200%;
      font-weight: 600;
      margin-left: 15%;
      font-family: 'helvetica';
      height: 50px;
      display: block;
      position: relative;
  }

  @media only screen and (max-width: 578px) {  
    .restoran-dugme{
      width: 70%;
      margin: auto;
      margin-top: 5%;
    }
 }