input[type="text"] {
  width: 70%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: large;
  border: 1.5px solid gray;
}

input[type="email"] {
  width: 70%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: large;
  border: 1.5px solid gray;
}

input[type="date"] {
  display: block;
  position: relative;
  padding: 1rem 3.5rem 1rem 1.3rem;
  margin-left: 15%;
  margin-top: 1.2%;
  font-size: 2rem;
  font-family: monospace;
  color: gray;
  font-weight: 200;
  -webkit-appearance: none;

  border: 1.5px solid gray;
  border-radius: 0.25rem;
  background: white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='22' viewBox='0 0 20 22'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23688EBB' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'%3E%3Crect width='18' height='18' y='2' rx='2'/%3E%3Cpath d='M13 0L13 4M5 0L5 4M0 8L18 8'/%3E%3C/g%3E%3C/svg%3E")
    right 1rem center no-repeat;

  cursor: pointer;
}

input[type="date"]:focus {
  outline: none;

  border-color: black;
  box-shadow: 0 0 0 0.05rem black;
}

::-webkit-datetime-edit {
}
::-webkit-datetime-edit-fields-wrapper {
}
::-webkit-datetime-edit-month-field:hover,
::-webkit-datetime-edit-day-field:hover,
::-webkit-datetime-edit-year-field:hover {
  background: #d2f8d2;
}
::-webkit-datetime-edit-text {
  opacity: 0;
}
::-webkit-clear-button,
::-webkit-inner-spin-button {
  display: none;
}
::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 2.5rem;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;

  opacity: 0;
  cursor: pointer;

  color: rgb(0, 255, 34) !important;
  background: #d2f8d2;
}

input[type="date"]:hover::-webkit-calendar-picker-indicator {
  opacity: 0.05;
}
input[type="date"]:hover::-webkit-calendar-picker-indicator:hover {
  opacity: 0.15;
}

.izabran-datum {
  color: black;
}

@media only screen and (max-width: 667px) {
  input[type="date"] {
    padding: 1rem 2.3rem 1rem 0.1rem;
    margin-right: 0rem !important;
  }
}
