.slikaOnama{
    width: 100%;
    height: 350px;
    -o-object-fit: cover;
       object-fit: cover;
    text-align: center;
}



.naslovOnama{
    position: absolute;
    font-size: xxx-large;
    color: white;
    text-shadow: 3px 3px black;
    font-weight: 900;
    
}



.o-nama-slika{
    position: relative;
    width: 100%;
    height: 20%;
}

.o-nama-slika h1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}