.sve2{
    margin-top: 3%;
}

.naslov{
    font-size: xxx-large;
    font-weight: 350;
    margin-bottom: 2%;
    margin-top: 5%;
}

.opis{
    font-size: medium;
    margin-bottom: 3%;
    margin-top: 4%;
}

.opsirnije{
    border-top: 3%;
    font-size: medium;
    color: black;
    text-decoration: none;
    border-bottom: solid 2px green;
    font-weight: 500;
}

.opsirnije:hover{
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.gif-logo{
    margin-bottom: 10%;
}


@media only screen and (max-height: 630px){
    .sve2{
        margin: auto;
    }
}


@media only screen and (max-width: 494px){
    .sve2{
        margin-top: -22%;
        margin: auto;
    }
}