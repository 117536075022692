* {
    box-sizing: border-box;
  }
  
  .container3{
      margin: 0 auto;
  }
  
  /* Clear floats */
  .row-ponuda:after {
    content: "";
    display: table;
    clear: both
  }
  
  /* 2/3 column */
  .column-66 {
    float: left;
    width: 60%;
    overflow: hidden;
  }
  
  /* 1/3 column */
  .column-33 {
    float: left;
    width: 40%;
  }

  .slika-za-tekst{
    max-width: 100%;
    object-fit: contain;
    -webkit-transition: all 2s ease; /* Safari and Chrome */
    -moz-transition: all 2s ease; /* Firefox */
    -ms-transition: all 2s ease; /* IE 9 */
    -o-transition: all 2s ease; /* Opera */
    transition: all 2s ease;
}

.row-ponuda{
    background-color: #F5F5F5;
}

.tekst-ponuda > h1{
    font-size: 3rem;
    margin-bottom: 10%;
    text-decoration: underline green;
    text-underline-position: under;
}


.tekst-ponuda{
    text-align:center;
}


.tekst-ponuda > li{
    color: green;
}

.ponuda-za-goste{
    padding-bottom: 1%;
}
 
.icon-ponuda{
    font-size: xx-large;
    margin-top: 10%;
}

.jezera-ul {
    list-style-type: none;
    padding-top: 3%; 
}

.jezera-ul > li{
    margin-top: 1%;
    text-align: center;
}

.icon-jezera{
    margin-right: 4%;
}

.zoom{
    transform: scale(1.15);
}

  /* Add responsiveness - make the columns appear on top of each other instead of next to each other on small screens */
  @media screen and (max-width: 1000px) {
    .column-66,
    .column-33 {
      width: 100%;
      text-align: center;
    }

    .icon-ponuda{
        margin-top: 5%;
        font-size: x-large;
    }

    .tekst-ponuda > h1{
        font-size: x-large;
        margin-bottom: 3.5%;
    }
    .ponuda-za-goste{
        padding-bottom: 0%;
        margin-bottom: -17%;
    }
  }
