.forma-restoran {
  width: 700px;
  margin: auto;
  padding-bottom: 5%;
  margin-top: -2%;
}

.kombo-restoran {
  display: flex;
  width: 70%;
  margin: auto;
  padding-top: 3%;
}

@media only screen and (max-width: 667px) {
  .forma-restoran {
    width: 130%;
    margin: auto;
    margin-left: -16%;
  }
  .odvoji {
    margin-top: 0.2%;
  }
}

.poruka:focus {
  border: 1.5px solid black;
}
