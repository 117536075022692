.nav-dugme {
  width: 25%;
  height: 50px;
  background-color: rgb(170, 152, 102);
  color: white;
  font-size: 200%;
  border: solid 1px;
  border-top: none;
  border-bottom: none;
  font-weight: 600;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: none !important;
}

.kreveti-icons {
  display: none;
  font-size: 2.8rem !important;
  margin-top: 1%;
  padding-bottom: 20%;
}

.nav-dugme-aktivno {
  background-color: rgb(250, 249, 249);
  color: rgb(141, 119, 60);
  border: none;
  pointer-events: none;
}

.broj-kreveta {
  font-size: medium;
}

@media only screen and (max-width: 744px) {
  .nav-dugme {
    font-size: 150%;
  }
}

@media only screen and (max-width: 570px) {
  .kreveti-naziv {
    display: none;
  }
  .kreveti-icons {
    display: block;
  }
  .dugmad {
    margin-top: -1.5%;
  }
}
