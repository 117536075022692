.loader2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 11;
    text-align: center;
    opacity: 1;
    -webkit-transition: opacity ease-out 2s;
    -moz-transition: opacity ease-out 2s;
    -o-transition: opacity ease-out 2s;
    transition: opacity ease-out 2s;
  }

  .smjestaj-opcije{
      position: relative;
  }

  .smjestaj-sadrzaj{
      opacity: 0;
  }

.smestaj-sadrzaj-ucitan{
      opacity: 1;
      -webkit-transition: opacity ease-in-out 1s;
      -moz-transition: opacity ease-in-out 1s;
      -o-transition: opacity ease-in-out 1s;
      transition: opacity ease-in-out 1s;
  }
