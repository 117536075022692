.karta {
  width: 99%;
  height: 600px;
  margin-bottom: 3%;
  padding-left: -15px;
  padding-right: -15px;
  opacity: 0.95;
  -o-object-fit: cover;
  object-fit: cover;
}

.ponuda {
  margin-top: 3%;
  margin-bottom: 5%;
}

.usluge {
  margin-bottom: 6%;
  border-top: dotted green;
  border-width: thin;
  margin-left: -15%;
  margin-right: -15%;
}

.traka {
  opacity: 1;
  z-index: 1;
  color: white;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 10%;
  text-shadow: 3px 3px black;
  font-size: 380%;
  transition: all ease-in-out 0.4s;

  margin-right: -35%;
  margin-left: 0%;
  padding: 0 15%;
}

.kolona {
  padding: 0 !important;
}

.kolona:hover {
  cursor: pointer;
}

.kolona2:hover > .traka {
  bottom: 50%;
  font-size: 380%;
  opacity: 1;
}

@media only screen and (max-width: 1400px) {
  .ponuda {
    margin-left: 1%;
    margin-right: 1%;
  }

  .karta {
    height: 600px;
    width: 45rem;
  }
  .kolona {
    width: 100% !important;
  }

  .traka {
    font-size: 280%;
  }

  .red {
    width: 75%;
    margin: auto !important;
  }
}

@media only screen and (max-width: 738px) {
  .hover > .traka {
    bottom: 50%;
    font-size: 380%;
  }

  .usluge {
    padding-bottom: 3%;
  }

  .kolona {
    width: 100%;
  }

  .karta {
    width: 50rem;
  }

  .red {
    width: 100% !important;
  }
}
