.dugme {
  bottom: 70%;
}

.dugme-strelica {
  position: absolute;
  z-index: 4;
  right: 10%;
  bottom: 40%;
}

.regulisi {
  position: relative;
}

.zaj {
  text-align: left;
  position: absolute;
  z-index: 4;
  top: 5%;
  left: 2%;
  padding: 0 5rem;
  background-color: rgba(46, 49, 49, 0.3);
}

.oka {
  font-size: 9rem;
  font-weight: bolder;
  color: white;
  text-shadow: 3px 3px 6px #000000;
  font-family: Merriweather;
}

.rijeci {
  font-size: 3rem;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  font-style: italic;
  font-family: Merriweather;
}

.strijela {
  width: 29%;
  margin-top: 8%;
  margin-left: 40%;
}

@media only screen and (max-width: 800px) {
  .oka {
    right: 38%;
    top: 15%;
    font-size: 4.5rem;
  }

  .rijeci {
    right: 25%;
    top: 20%;
    font-size: xx-large;
  }
  .dugme-strelica {
    left: 10%;
  }
}

@media only screen and (max-width: 1351px) {
  .strijela {
    display: none;
  }
  .oka {
    left: 3%;
    margin-right: -10%;
  }

  .zaj {
    top: 18%;
    background-color: rgba(46, 49, 49, 0.1);
  }

  .rijeci {
    left: 5%;
    font-size: x-large;
  }

  .dugme-strelica {
    right: 10%;
  }
}

.karusel-komentari-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 10%;
  position: relative;
}

@media screen and (max-height: 418px) {
  .dugme-strelica {
    top: 8%;
    max-width: 20px;
    left: 100%;
  }

  .strijela {
    display: none;
  }

  .zaj {
    bottom: 10%;
  }
}
