.check-list {
   margin: auto;
    margin-left: -30%;
  }
  
  .check-list li {
    position: relative;
    display: block;
    list-style-type: none;
    margin-bottom: 0.5rem;
    margin: auto;
    text-align: left;
    margin-right: 20%;

  }
  
  .check-list li:before {
      content: '';
      margin-left: -2.5rem;
      position: absolute;
      top: 3%;
      width: 5px;
      height: 11px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: #00a8a8;
      transform-origin: bottom left;
      transform: rotate(45deg);
    
  }
  
  
  /* Boilerplate stuff */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  section {
    align-items: center;
    margin-bottom: -2%;

  }
  
.lista-dogadjaja{
    width: 50%;
    margin: auto;
    margin-top: 2rem;
    margin-right: -9.5%;
  }
  
  @media only screen and (max-width: 862px) {
    .check-list {
         margin-left: -72%;
         margin-right: -15%;
       }
       .check-list li:before{
        margin-left: -2rem;
       }

       .lista-dogadjaja{
        margin-right: -22.5%;
      }
}

.karusel-kolonija{
    margin-top: 5%;
    margin-bottom: 6%;
    width:100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.kontaktirajte-nas{
    border-top: 3%;
    font-size: medium;
    color: black;
    text-decoration: none;
    border-bottom: solid 2px green;
    font-weight: 500;
    cursor: pointer;
}

.kontaktirajte-nas:hover{
    text-decoration: none;
    color: black;
}

.org-dogadjaja > .nasa-vizija{
    margin-bottom: -2%;
}