.jezik{
    display: flex;
    margin-top: 0.2%;
  }

  .neaktivan{
    width: 45px;
    opacity: 0.2;
    height: 25px;
    border: 0.2px solid;
    border-color: gold;
  }
  
  .aktivan{
    border: 0.2px solid;
    border-color: gold;
    height: 25px;
  }
  
  .sakriven{
      display: none;
  }

  .jezik:hover{
    cursor: pointer;
  } 