.datumi {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 80%;
  padding-right: 10%;
  margin-left: 8%;
}

.combo-rezervacija {
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin: auto;
  margin-top: 3%;
}

.naslov-rezervacija > h1 {
  color: #8caa58;
  text-shadow: 1px 1px black;
  font-size: 4.5rem;
  display: inline;
}

.naslov-rezervacija {
  padding-bottom: 5%;
  margin-top: -8%;
  padding-top: 5%;
}

.alert-box {
  position: absolute;
  align-self: center;
  top: 50;
}

input {
  -webkit-appearance: none !important;
}

.smjestaj-input {
  height: 50px;
  width: 185px;
}

.popup-content {
  text-align: center;
  width: 510px;
  padding: 5% 0;
}

.odrasli-rez-smjestaj {
  margin-left: -118%;
}

.djeca-rez-smjestaj > .djeca {
  margin-left: -155%;
  margin-top: 23%;
}

.odrasli-rez-smjestaj > .odrasli {
  margin: auto;
}

.forma-smjestaj {
  width: 100%;
}

@media only screen and (max-width: 531px) {
  .popup-content {
    width: 90%;
  }
  .combo-rezervacija {
    width: 90%;
  }
  .datumi {
    padding-top: 2%;
    width: 100%;
  }
  .smjestaj-dugme {
    width: 90% !important;
    margin-left: 5% !important;
  }
}

@media only screen and (max-width: 401px) {
  .popup-content {
    width: 95%;
  }
}

@media only screen and (max-width: 384px) {
  .popup-content {
    width: 95%;
    padding-top: 8% !important;
  }
}

@media only screen and (max-width: 450px) {
  .sobe {
    margin-top: -16.5%;
  }
  .datumi {
    flex-direction: column;
    margin-top: -2%;
    margin-left: -5%;
  }
  .smjestaj-input {
    width: 90% !important;
  }
  .dolazak {
    margin-left: -40%;
  }

  .odlazak-datum {
    margin-left: -40%;
    padding-top: 2%;
  }
}
