.predzadnji{
    background-color: gray;
    padding: 0;
    margin-right: -10%;
    margin-top: 3%;
}

.zadnji{
    background-color: rgb(109, 107, 107);
    padding: 1.5% 0;
    margin-bottom: -3%;
    color: gray;
}

.logoDno{
    padding-top: 28%;
    font-size: x-large;
}
.opisDno{
    font-size: large;
    margin-bottom: 3%;
    color: white;
}

.desnoDno{
    color: white;
    text-align: left;
    margin-left: 15%;
    margin-top: 12%;
}

.redovi{
    font-size: 140%;
    font-weight: 400;
    margin-top: 1.5%;
    margin-left: -30%;
}

.kontaktInfo{
    margin-left: -30%;
    color: #40E0D0;
    font-size: 140%;
    margin-bottom: 3%;
    margin-top: -2%;
    font-weight: bold;
}

.dno{
    margin-top: -4%;
}

.lijevoDno{
    margin-top: 8%;
    margin-left: 15%;
}

@media only screen and (max-width: 500px) {
    .dno{
        margin-top: 8%;
    }
    .predzadnji{
        margin-top: 16%;
    }

    .desnoDno{
        margin-top: 20%;
    }
}

@media screen and (max-height: 418px) {
    .dno{
        top: 100%;
    }
  }