html,
body {
  height: 100%;
}

.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  min-width: 300px;
  min-height: 60px;
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #313133;
  background: #4fd1c5;
  background: linear-gradient(
    90deg,
    rgba(129, 230, 217, 1) 0%,
    rgba(79, 209, 197, 1) 100%
  );
  border: none;
  border-radius: 1000px;
  box-shadow: 1px 1px 2px rgba(79, 209, 197, 0.64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  padding: 10px;
  right: 9%;
  top: 36%;
}

button::before {
  content: "";
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

.button:hover,
.button:focus {
  color: #313133;
  transform: translateY(-6px);
}

button:hover::before,
button:focus::before {
  opacity: 1;
}

button::after {
  content: "";
  border-radius: 100%;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button:hover::after,
button:focus::after {
  -webkit-animation: none;
  animation: none;
  display: none;
}

.rezervacija-forma > .close {
  cursor: pointer;
  position: absolute;
  opacity: 1 !important;
  display: block;
  padding: 8px 10px 8px 10px;
  line-height: 15px;
  right: -8px;
  top: -8px;
  font-size: 20px;
  background: #9dad7f;
  border-radius: 18px;
  border: 1px solid #cfcece;
  color: white;
}

.rezervacija-forma > .close:hover {
  opacity: 1 !important;
}

.close::after {
  outline: 0;
  outline-color: transparent;
  outline-style: none;
}
.close::before {
  outline: 0;
  outline-color: transparent;
  outline-style: none;
}
.close {
  outline: 0;
  outline-color: transparent;
  outline-style: none;
}

@media only screen and (max-width: 600px) {
  .button {
    min-width: 160px;
    min-height: 50px;
    font-size: large;
    left: 50%;
    top: 55%;
  }

  button::before {
    content: "";
    border-radius: 1000px;
    min-width: calc(150px + 12px);
    min-height: calc(30px + 22px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
  }
}

/* animation */

@-webkit-keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.03s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  border-radius: 15px;
}
