@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap);
html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

._3LDgJ {
  border-radius: 0.2rem !important;
  border-color: gray !important;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 80vh;
  margin-top: 0%;
  opacity: 1;
  transition: opacity ease-out 2s;
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 5000ms linear 5000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 5000ms linear;
}

.fade-exit-done {
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .loader {
    left: 43%;
  }
}

.loader-hidden {
  opacity: 0;
}

.App {
  text-align: center;
  opacity: 0;
}

.fade-in-app {
  opacity: 1;
  transition: opacity ease-in 0.15s;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1.4s ease-in-out;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1.4s ease-in-out;
}

.fade-exit {
  display: none;
}

.fade-exit.fade-exit-active {
  opacity: 0;
}
.fade-exit-done {
  opacity: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.back-to-top {
  z-index: 7;
}

.nikola {
  height: 700px;
  margin: auto;
}

.container2 {
  position: relative;
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(100%);
  padding: 0%;
  margin: 0 -15%;
}

.carousel-control-next-icon:hover {
  cursor: pointer;
}

.carousel-control-prev-icon:hover {
  cursor: pointer;
}

.komentar > img {
  width: 80% !important;
  margin: auto;
}

.kolonija.carousel-inner {
  padding: 0 10%;
}

.view.restoran {
  width: 66%;
  margin: auto;
}

.restoran > img {
  height: 100%;
  margin: auto;
  object-fit: cover;
}

.strelica-lijevo {
  margin-left: 1.5%;
}

.strelica-desno {
  margin-right: 1.5%;
}

@media only screen and (max-width: 1063px) {
  .view.restoran {
    width: 100%;
    height: 250px;
  }

  .carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
    padding: 0%;
    margin: 0 -5.5%;
  }

  .strelica-lijevo {
    margin-left: 1%;
    filter: invert(0);
  }
  .strelica-desno {
    margin-right: 1%;
    filter: invert(0);
  }
  .nikola {
    height: 600px !important;
  }
}

.min-vh-100 {
  width: 100%;
  object-fit: cover;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dugme {
  bottom: 70%;
}

.dugme-strelica {
  position: absolute;
  z-index: 4;
  right: 10%;
  bottom: 40%;
}

.regulisi {
  position: relative;
}

.zaj {
  text-align: left;
  position: absolute;
  z-index: 4;
  top: 5%;
  left: 2%;
  padding: 0 5rem;
  background-color: rgba(46, 49, 49, 0.3);
}

.oka {
  font-size: 9rem;
  font-weight: bolder;
  color: white;
  text-shadow: 3px 3px 6px #000000;
  font-family: Merriweather;
}

.rijeci {
  font-size: 3rem;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  font-style: italic;
  font-family: Merriweather;
}

.strijela {
  width: 29%;
  margin-top: 8%;
  margin-left: 40%;
}

@media only screen and (max-width: 800px) {
  .oka {
    right: 38%;
    top: 15%;
    font-size: 4.5rem;
  }

  .rijeci {
    right: 25%;
    top: 20%;
    font-size: xx-large;
  }
  .dugme-strelica {
    left: 10%;
  }
}

@media only screen and (max-width: 1351px) {
  .strijela {
    display: none;
  }
  .oka {
    left: 3%;
    margin-right: -10%;
  }

  .zaj {
    top: 18%;
    background-color: rgba(46, 49, 49, 0.1);
  }

  .rijeci {
    left: 5%;
    font-size: x-large;
  }

  .dugme-strelica {
    right: 10%;
  }
}

.karusel-komentari-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 10%;
  position: relative;
}

@media screen and (max-height: 418px) {
  .dugme-strelica {
    top: 8%;
    max-width: 20px;
    left: 100%;
  }

  .strijela {
    display: none;
  }

  .zaj {
    bottom: 10%;
  }
}

html,
body {
  height: 100%;
}

.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  min-width: 300px;
  min-height: 60px;
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #313133;
  background: #4fd1c5;
  background: linear-gradient(
    90deg,
    rgba(129, 230, 217, 1) 0%,
    rgba(79, 209, 197, 1) 100%
  );
  border: none;
  border-radius: 1000px;
  box-shadow: 1px 1px 2px rgba(79, 209, 197, 0.64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  padding: 10px;
  right: 9%;
  top: 36%;
}

button::before {
  content: "";
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

.button:hover,
.button:focus {
  color: #313133;
  transform: translateY(-6px);
}

button:hover::before,
button:focus::before {
  opacity: 1;
}

button::after {
  content: "";
  border-radius: 100%;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button:hover::after,
button:focus::after {
  animation: none;
  display: none;
}

.rezervacija-forma > .close {
  cursor: pointer;
  position: absolute;
  opacity: 1 !important;
  display: block;
  padding: 8px 10px 8px 10px;
  line-height: 15px;
  right: -8px;
  top: -8px;
  font-size: 20px;
  background: #9dad7f;
  border-radius: 18px;
  border: 1px solid #cfcece;
  color: white;
}

.rezervacija-forma > .close:hover {
  opacity: 1 !important;
}

.close::after {
  outline: 0;
  outline-color: transparent;
  outline-style: none;
}
.close::before {
  outline: 0;
  outline-color: transparent;
  outline-style: none;
}
.close {
  outline: 0;
  outline-color: transparent;
  outline-style: none;
}

@media only screen and (max-width: 600px) {
  .button {
    min-width: 160px;
    min-height: 50px;
    font-size: large;
    left: 50%;
    top: 55%;
  }

  button::before {
    content: "";
    border-radius: 1000px;
    min-width: calc(150px + 12px);
    min-height: calc(30px + 22px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
  }
}

/* animation */

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.03s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  border-radius: 15px;
}

.datumi {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 80%;
  padding-right: 10%;
  margin-left: 8%;
}

.combo-rezervacija {
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin: auto;
  margin-top: 3%;
}

.naslov-rezervacija > h1 {
  color: #8caa58;
  text-shadow: 1px 1px black;
  font-size: 4.5rem;
  display: inline;
}

.naslov-rezervacija {
  padding-bottom: 5%;
  margin-top: -8%;
  padding-top: 5%;
}

.alert-box {
  position: absolute;
  align-self: center;
  top: 50;
}

input {
  -webkit-appearance: none !important;
}

.smjestaj-input {
  height: 50px;
  width: 185px;
}

.popup-content {
  text-align: center;
  width: 510px;
  padding: 5% 0;
}

.odrasli-rez-smjestaj {
  margin-left: -118%;
}

.djeca-rez-smjestaj > .djeca {
  margin-left: -155%;
  margin-top: 23%;
}

.odrasli-rez-smjestaj > .odrasli {
  margin: auto;
}

.forma-smjestaj {
  width: 100%;
}

@media only screen and (max-width: 531px) {
  .popup-content {
    width: 90%;
  }
  .combo-rezervacija {
    width: 90%;
  }
  .datumi {
    padding-top: 2%;
    width: 100%;
  }
  .smjestaj-dugme {
    width: 90% !important;
    margin-left: 5% !important;
  }
}

@media only screen and (max-width: 401px) {
  .popup-content {
    width: 95%;
  }
}

@media only screen and (max-width: 384px) {
  .popup-content {
    width: 95%;
    padding-top: 8% !important;
  }
}

@media only screen and (max-width: 450px) {
  .sobe {
    margin-top: -16.5%;
  }
  .datumi {
    flex-direction: column;
    margin-top: -2%;
    margin-left: -5%;
  }
  .smjestaj-input {
    width: 90% !important;
  }
  .dolazak {
    margin-left: -40%;
  }

  .odlazak-datum {
    margin-left: -40%;
    padding-top: 2%;
  }
}

input[type="text"] {
  width: 70%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: large;
  border: 1.5px solid gray;
}

input[type="email"] {
  width: 70%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: large;
  border: 1.5px solid gray;
}

input[type="date"] {
  display: block;
  position: relative;
  padding: 1rem 3.5rem 1rem 1.3rem;
  margin-left: 15%;
  margin-top: 1.2%;
  font-size: 2rem;
  font-family: monospace;
  color: gray;
  font-weight: 200;
  -webkit-appearance: none;

  border: 1.5px solid gray;
  border-radius: 0.25rem;
  background: white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='22' viewBox='0 0 20 22'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23688EBB' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'%3E%3Crect width='18' height='18' y='2' rx='2'/%3E%3Cpath d='M13 0L13 4M5 0L5 4M0 8L18 8'/%3E%3C/g%3E%3C/svg%3E")
    right 1rem center no-repeat;

  cursor: pointer;
}

input[type="date"]:focus {
  outline: none;

  border-color: black;
  box-shadow: 0 0 0 0.05rem black;
}

::-webkit-datetime-edit {
}
::-webkit-datetime-edit-fields-wrapper {
}
::-webkit-datetime-edit-month-field:hover,
::-webkit-datetime-edit-day-field:hover,
::-webkit-datetime-edit-year-field:hover {
  background: #d2f8d2;
}
::-webkit-datetime-edit-text {
  opacity: 0;
}
::-webkit-clear-button,
::-webkit-inner-spin-button {
  display: none;
}
::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 2.5rem;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;

  opacity: 0;
  cursor: pointer;

  color: rgb(0, 255, 34) !important;
  background: #d2f8d2;
}

input[type="date"]:hover::-webkit-calendar-picker-indicator {
  opacity: 0.05;
}
input[type="date"]:hover::-webkit-calendar-picker-indicator:hover {
  opacity: 0.15;
}

.izabran-datum {
  color: black;
}

@media only screen and (max-width: 667px) {
  input[type="date"] {
    padding: 1rem 2.3rem 1rem 0.1rem;
    margin-right: 0rem !important;
  }
}


.book{
    width: 7%;
    border: none;
    background-color: #9dad7f;
    background-size: auto;
    color: white;
    font-size: medium;
    font-weight: 600;
    margin-right: 3%;
    font-family: 'helvetica';
    height: 102%;
  }

  .book2{
    width: 50%;
    height: 150%;
    border: none;
    background-color: #9dad7f;
    background-size: auto;
    color: white;
    font-size: medium;
    font-weight: 600;
    margin-left: 7%;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: -2.5%;
  }

  .kontakt-dugme{
    margin-top: 1%;
    width: 160px;
    border: none;
    background-color: #9dad7f;
    background-size: auto;
    color: white;
    font-size: 160%;
    font-weight: 600;
    margin-left: 30%;
    font-family: 'helvetica';
    height: 50px;
    display: block;
    position: relative;
  }

  .restoran-dugme{
    margin-top: 2.5%;
    width: 490px;
    border: none;
    background-color: #9dad7f;
    background-size: auto;
    color: white;
    font-size: 200%;
    font-weight: 600;
    margin-left: 15%;
    font-family: 'helvetica';
    height: 50px;
    display: block;
    position: relative;
  }

  .smjestaj-dugme{
      margin-top: 2.5%;
      width: 70%;
      border: none;
      background-color: #9dad7f;
      background-size: auto;
      color: white;
      font-size: 200%;
      font-weight: 600;
      margin-left: 15%;
      font-family: 'helvetica';
      height: 50px;
      display: block;
      position: relative;
  }

  @media only screen and (max-width: 578px) {  
    .restoran-dugme{
      width: 70%;
      margin: auto;
      margin-top: 5%;
    }
 }
.sve2{
    margin-top: 3%;
}

.naslov{
    font-size: xxx-large;
    font-weight: 350;
    margin-bottom: 2%;
    margin-top: 5%;
}

.opis{
    font-size: medium;
    margin-bottom: 3%;
    margin-top: 4%;
}

.opsirnije{
    border-top: 3%;
    font-size: medium;
    color: black;
    text-decoration: none;
    border-bottom: solid 2px green;
    font-weight: 500;
}

.opsirnije:hover{
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.gif-logo{
    margin-bottom: 10%;
}


@media only screen and (max-height: 630px){
    .sve2{
        margin: auto;
    }
}


@media only screen and (max-width: 494px){
    .sve2{
        margin-top: -22%;
        margin: auto;
    }
}
.vizija {
  margin-top: 3%;
  font-size: xxx-large;
  font-weight: 400;
  font-family: Merriweather;
}

@media only screen and (max-width: 500px) {
  .vizija {
    margin-top: 9%;
    font-size: xx-large;
    font-weight: 400;
  }
}

.karta {
  width: 99%;
  height: 600px;
  margin-bottom: 3%;
  padding-left: -15px;
  padding-right: -15px;
  opacity: 0.95;
  object-fit: cover;
}

.ponuda {
  margin-top: 3%;
  margin-bottom: 5%;
}

.usluge {
  margin-bottom: 6%;
  border-top: dotted green;
  border-width: thin;
  margin-left: -15%;
  margin-right: -15%;
}

.traka {
  opacity: 1;
  z-index: 1;
  color: white;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 10%;
  text-shadow: 3px 3px black;
  font-size: 380%;
  transition: all ease-in-out 0.4s;

  margin-right: -35%;
  margin-left: 0%;
  padding: 0 15%;
}

.kolona {
  padding: 0 !important;
}

.kolona:hover {
  cursor: pointer;
}

.kolona2:hover > .traka {
  bottom: 50%;
  font-size: 380%;
  opacity: 1;
}

@media only screen and (max-width: 1400px) {
  .ponuda {
    margin-left: 1%;
    margin-right: 1%;
  }

  .karta {
    height: 600px;
    width: 45rem;
  }
  .kolona {
    width: 100% !important;
  }

  .traka {
    font-size: 280%;
  }

  .red {
    width: 75%;
    margin: auto !important;
  }
}

@media only screen and (max-width: 738px) {
  .hover > .traka {
    bottom: 50%;
    font-size: 380%;
  }

  .usluge {
    padding-bottom: 3%;
  }

  .kolona {
    width: 100%;
  }

  .karta {
    width: 50rem;
  }

  .red {
    width: 100% !important;
  }
}

/* Clear floats */
.row-smjestaj:after {
    content: "";
    display: table;
    clear: both
  }
  
  /* 2/3 column */
  .column-55 {
    float: left;
    width: 55%;
    overflow: hidden;
    
  }
  .book::before{
    display: none;
  }
  
  /* 1/3 column */
  .column-45 {
    float: left;
    width: 45%;
    background-color:rgb(250, 249, 249);
  }

  .container4{
    margin: 0 auto;
    margin-bottom: 4%;
}

@media screen and (max-width: 1535px) {
    .column-55,
    .column-45 {
      width: 100%;
      text-align: center;
    }
}
.slikaOnama{
    width: 100%;
    height: 350px;
    object-fit: cover;
    text-align: center;
}



.naslovOnama{
    position: absolute;
    font-size: xxx-large;
    color: white;
    text-shadow: 3px 3px black;
    font-weight: 900;
    
}



.o-nama-slika{
    position: relative;
    width: 100%;
    height: 20%;
}

.o-nama-slika h1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}
.nav-dugme {
  width: 25%;
  height: 50px;
  background-color: rgb(170, 152, 102);
  color: white;
  font-size: 200%;
  border: solid 1px;
  border-top: none;
  border-bottom: none;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  outline: none !important;
}

.kreveti-icons {
  display: none;
  font-size: 2.8rem !important;
  margin-top: 1%;
  padding-bottom: 20%;
}

.nav-dugme-aktivno {
  background-color: rgb(250, 249, 249);
  color: rgb(141, 119, 60);
  border: none;
  pointer-events: none;
}

.broj-kreveta {
  font-size: medium;
}

@media only screen and (max-width: 744px) {
  .nav-dugme {
    font-size: 150%;
  }
}

@media only screen and (max-width: 570px) {
  .kreveti-naziv {
    display: none;
  }
  .kreveti-icons {
    display: block;
  }
  .dugmad {
    margin-top: -1.5%;
  }
}

.loader2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 11;
    text-align: center;
    opacity: 1;
    transition: opacity ease-out 2s;
  }

  .smjestaj-opcije{
      position: relative;
  }

  .smjestaj-sadrzaj{
      opacity: 0;
  }

.smestaj-sadrzaj-ucitan{
      opacity: 1;
      transition: opacity ease-in-out 1s;
  }

.carousel-status{
    display: none;
}

.thumb-slika > img{
    
    object-fit: contain;
    height: auto;   
    overflow: hidden;
}

.thumbs > .selected{
    border-color: rgb(160, 139, 80) !important;
}

.thumb-slika{
    cursor: zoom-in;
    pointer-events: none;
    width: 90% !important;
    margin: auto;
    margin-top: 5%;
}

.thumb-slika:hover{
    filter: brightness(75%);
}

.thumbs.animated{
    transform: none !important;
}

.thumbs > li:hover{
    border-color: rgb(160, 139, 80) !important;
}

@media only screen and (max-width: 744px){
    .thumb-slika{
        width: 97% !important;
    }
}

@media only screen and (max-width: 1535px){
    .thumb-slika{
        cursor: default;
    }
    .thumb-slika:hover{
        filter: brightness(100%);
    }
}

.logo-smjestaj-ponuda {
  margin-left: 25%;
  margin-right: -15%;
}

.logo-smjestaj-sve {
  display: block;
  font-size: 1.8rem;
  margin-bottom: 2%;
  color: rgb(168, 147, 93);
  text-align: left;
}

.opsirnije-ponuda {
  margin-left: -130% !important;
  margin-bottom: 120% !important;
}

.opsirnije-ponuda-div {
  margin-top: 10%;
}

.logo-smjestaj {
  margin-right: 5%;
}

.cijena-smjestaj {
  font-size: large;
  margin-left: -70%;
  margin-top: 3%;
}

.evri {
  font-size: x-large;
  color: rgb(148, 119, 46);
}

.evri2 {
  font-size: medium;
  color: rgb(148, 99 119, 46);
}

.djeca-smjestaj {
  margin-top: -2%;
  font-size: small;
}

.smjestaj-ponuda > .red {
  margin-top: -2%;
}

@media only screen and (max-width: 450px) {
  .logo-smjestaj-ponuda {
    margin-left: 2% !important;
  }
  .lisp2 {
    margin-left: 25% !important;
  }
  .evri {
    font-size: small;
  }
  .evri2 {
    font-size: small;
  }
  .cijena-tekst {
    font-size: small;
  }
  .opsirnije-ponuda {
    font-size: x-small;
    margin-left: 0 !important;
  }
  .cijena-smjestaj {
    margin-left: -10%;
    margin-top: 10%;
  }
  .djeca-smjestaj {
    font-size: x-small;
  }
  .logo-smjestaj-sve {
    font-size: 1.2rem;
  }
  .opsirnije-ponuda-div {
    margin-top: 55% !important;
  }
}

@media only screen and (max-width: 1050px) {
  .lsp {
    margin-left: 15%;
  }
  .lsp2 {
    margin-left: 18%;
  }
  .opsirnije-ponuda {
    margin-left: -140% !important;
  }
  .cijena-smjestaj {
    margin-left: -40%;
    margin-top: 10%;
  }
  .opsirnije-ponuda-div {
    margin-top: 15%;
    margin-bottom: 15%;
  }
}

.nasa-vizija {
  margin-top: 2%;
  margin-bottom: 5%;
  padding: 0 20%;
}

@media only screen and (max-width: 800px) {
  .nasa-vizija {
    padding: 1.5%;
  }
}

@media only screen and (max-width: 500px) {
  .nasa-vizija {
    font-size: large;
    font-weight: 300;
  }
}

.karusel-restoran {
  margin-top: 2%;
  margin-bottom: 3%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.forma-restoran {
  width: 700px;
  margin: auto;
  padding-bottom: 5%;
  margin-top: -2%;
}

.kombo-restoran {
  display: flex;
  width: 70%;
  margin: auto;
  padding-top: 3%;
}

@media only screen and (max-width: 667px) {
  .forma-restoran {
    width: 130%;
    margin: auto;
    margin-left: -16%;
  }
  .odvoji {
    margin-top: 0.2%;
  }
}

.poruka:focus {
  border: 1.5px solid black;
}

.check-list {
   margin: auto;
    margin-left: -30%;
  }
  
  .check-list li {
    position: relative;
    display: block;
    list-style-type: none;
    margin-bottom: 0.5rem;
    margin: auto;
    text-align: left;
    margin-right: 20%;

  }
  
  .check-list li:before {
      content: '';
      margin-left: -2.5rem;
      position: absolute;
      top: 3%;
      width: 5px;
      height: 11px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: #00a8a8;
      transform-origin: bottom left;
      transform: rotate(45deg);
    
  }
  
  
  /* Boilerplate stuff */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  section {
    align-items: center;
    margin-bottom: -2%;

  }
  
.lista-dogadjaja{
    width: 50%;
    margin: auto;
    margin-top: 2rem;
    margin-right: -9.5%;
  }
  
  @media only screen and (max-width: 862px) {
    .check-list {
         margin-left: -72%;
         margin-right: -15%;
       }
       .check-list li:before{
        margin-left: -2rem;
       }

       .lista-dogadjaja{
        margin-right: -22.5%;
      }
}

.karusel-kolonija{
    margin-top: 5%;
    margin-bottom: 6%;
    width:100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.kontaktirajte-nas{
    border-top: 3%;
    font-size: medium;
    color: black;
    text-decoration: none;
    border-bottom: solid 2px green;
    font-weight: 500;
    cursor: pointer;
}

.kontaktirajte-nas:hover{
    text-decoration: none;
    color: black;
}

.org-dogadjaja > .nasa-vizija{
    margin-bottom: -2%;
}
.slikaOnama{
    width: 100%;
    height: 350px;
    object-fit: cover;
}


@media only screen and (max-width: 500px) {
    .lokacija{
        margin-bottom: -17%;
    }
}
.jezik{
    display: flex;
    margin-top: 0.2%;
  }

  .neaktivan{
    width: 45px;
    opacity: 0.2;
    height: 25px;
    border: 0.2px solid;
    border-color: gold;
  }
  
  .aktivan{
    border: 0.2px solid;
    border-color: gold;
    height: 25px;
  }
  
  .sakriven{
      display: none;
  }

  .jezik:hover{
    cursor: pointer;
  } 

.facebook{
    margin-top: 0.55%;
    margin-left: 0.8%;
    margin-right: 1%;
  }
  
  .fejs{ 
    font-size: large;
    color: #3b5998;
  }

  .fejs2{
    color: white;
    font-size: large;
    margin-right: -15%;
    margin-left: -2%;
    margin-bottom: 10%;
    transition: color ease-in-out 0.3s;
  }

  .fejs2:hover{
    color: #40E0D0;
  }
.instagram{
    margin-top: 0.6%;
    margin-left: 35%;
  }
  
  .insta{
    font-size: large;
    color: #fff;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  }


  .insta2{
    color: white;
    font-size: large;
    margin-left: -18%;
    margin-bottom: 9.5%;
    transition: color ease-in-out 0.3s;
  }

  .insta2:hover{
    color: #40E0D0;
  }
.sve{
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin-right: 0;
  width: 65%;
  margin-left: -10%;
  margin-right: -20%;
  position: relative;
  padding-right: 0;
  left: -5%;
}

.fa-mobile-phone{
   font-size:36px;
}

.zaglavlje{
   margin-bottom: 1%;
}
.logo{
   margin-left: -1%;
   margin-top: 0.2%;
   transition: all ease-in-out;
}

.logo:hover{
   cursor: pointer;
}


@media only screen and (max-width: 939px) {
   
   .logo{
      margin-left: -13%;
      margin-top: -0.7%;
      
   }

}

.kontakt-info-tekst{
    display: inline;
    
}

.kontakt-dimenzije{
    text-align: left;
    width: 347px;
    margin-left: 51%;
    margin-top: 10%;
}

.red-kontakt{
    margin-bottom: 3%;
}

.siva-slova{
    color: gray;
    justify-content: space-between;
}

.kontakt-dimenzije > .vizija{
    margin-bottom: 10%;
}

.kontakt-info-tekst > .nasa-vizija{
    padding-left: 0;
    margin-left: 0%;
}

.poruka{
    width: 70%;
    font-size: large;
    resize: none;
    padding: 12px 20px;
    margin-top: 1%;
    border:1.5px solid gray;
}

.kontakt-forma{
    margin-top: 9%;
    margin-bottom:auto;
    text-align: right;
    margin-bottom: 15%;
    margin-right: -70%;
}

@media only screen and (max-width: 945px) {
   .kontakt-dimenzije{
       margin-left: 5%;
   }

   .kontakt-forma{
       margin-right: -75%;
   }
 
}

@media only screen and (max-width: 767px) {
   
    .kontakt-dimenzije{
        margin: auto;
        padding-left: 5%;
        padding-top: 5%;
        padding-bottom: 10%;
        
    }

    .kontakt-forma{
        margin: auto;
        padding-left: 0;
        margin-right: 3%;
        margin-left: -38%;
        padding-bottom: 15%;
    }
 
}



.predzadnji{
    background-color: gray;
    padding: 0;
    margin-right: -10%;
    margin-top: 3%;
}

.zadnji{
    background-color: rgb(109, 107, 107);
    padding: 1.5% 0;
    margin-bottom: -3%;
    color: gray;
}

.logoDno{
    padding-top: 28%;
    font-size: x-large;
}
.opisDno{
    font-size: large;
    margin-bottom: 3%;
    color: white;
}

.desnoDno{
    color: white;
    text-align: left;
    margin-left: 15%;
    margin-top: 12%;
}

.redovi{
    font-size: 140%;
    font-weight: 400;
    margin-top: 1.5%;
    margin-left: -30%;
}

.kontaktInfo{
    margin-left: -30%;
    color: #40E0D0;
    font-size: 140%;
    margin-bottom: 3%;
    margin-top: -2%;
    font-weight: bold;
}

.dno{
    margin-top: -4%;
}

.lijevoDno{
    margin-top: 8%;
    margin-left: 15%;
}

@media only screen and (max-width: 500px) {
    .dno{
        margin-top: 8%;
    }
    .predzadnji{
        margin-top: 16%;
    }

    .desnoDno{
        margin-top: 20%;
    }
}

@media screen and (max-height: 418px) {
    .dno{
        top: 100%;
    }
  }
* {
    box-sizing: border-box;
  }
  
  .container3{
      margin: 0 auto;
  }
  
  /* Clear floats */
  .row-ponuda:after {
    content: "";
    display: table;
    clear: both
  }
  
  /* 2/3 column */
  .column-66 {
    float: left;
    width: 60%;
    overflow: hidden;
  }
  
  /* 1/3 column */
  .column-33 {
    float: left;
    width: 40%;
  }

  .slika-za-tekst{
    max-width: 100%;
    object-fit: contain; /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
    transition: all 2s ease;
}

.row-ponuda{
    background-color: #F5F5F5;
}

.tekst-ponuda > h1{
    font-size: 3rem;
    margin-bottom: 10%;
    -webkit-text-decoration: underline green;
            text-decoration: underline green;
    text-underline-position: under;
}


.tekst-ponuda{
    text-align:center;
}


.tekst-ponuda > li{
    color: green;
}

.ponuda-za-goste{
    padding-bottom: 1%;
}
 
.icon-ponuda{
    font-size: xx-large;
    margin-top: 10%;
}

.jezera-ul {
    list-style-type: none;
    padding-top: 3%; 
}

.jezera-ul > li{
    margin-top: 1%;
    text-align: center;
}

.icon-jezera{
    margin-right: 4%;
}

.zoom{
    transform: scale(1.15);
}

  /* Add responsiveness - make the columns appear on top of each other instead of next to each other on small screens */
  @media screen and (max-width: 1000px) {
    .column-66,
    .column-33 {
      width: 100%;
      text-align: center;
    }

    .icon-ponuda{
        margin-top: 5%;
        font-size: x-large;
    }

    .tekst-ponuda > h1{
        font-size: x-large;
        margin-bottom: 3.5%;
    }
    .ponuda-za-goste{
        padding-bottom: 0%;
        margin-bottom: -17%;
    }
  }

