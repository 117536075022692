.logo-smjestaj-ponuda {
  margin-left: 25%;
  margin-right: -15%;
}

.logo-smjestaj-sve {
  display: block;
  font-size: 1.8rem;
  margin-bottom: 2%;
  color: rgb(168, 147, 93);
  text-align: left;
}

.opsirnije-ponuda {
  margin-left: -130% !important;
  margin-bottom: 120% !important;
}

.opsirnije-ponuda-div {
  margin-top: 10%;
}

.logo-smjestaj {
  margin-right: 5%;
}

.cijena-smjestaj {
  font-size: large;
  margin-left: -70%;
  margin-top: 3%;
}

.evri {
  font-size: x-large;
  color: rgb(148, 119, 46);
}

.evri2 {
  font-size: medium;
  color: rgb(148, 99 119, 46);
}

.djeca-smjestaj {
  margin-top: -2%;
  font-size: small;
}

.smjestaj-ponuda > .red {
  margin-top: -2%;
}

@media only screen and (max-width: 450px) {
  .logo-smjestaj-ponuda {
    margin-left: 2% !important;
  }
  .lisp2 {
    margin-left: 25% !important;
  }
  .evri {
    font-size: small;
  }
  .evri2 {
    font-size: small;
  }
  .cijena-tekst {
    font-size: small;
  }
  .opsirnije-ponuda {
    font-size: x-small;
    margin-left: 0 !important;
  }
  .cijena-smjestaj {
    margin-left: -10%;
    margin-top: 10%;
  }
  .djeca-smjestaj {
    font-size: x-small;
  }
  .logo-smjestaj-sve {
    font-size: 1.2rem;
  }
  .opsirnije-ponuda-div {
    margin-top: 55% !important;
  }
}

@media only screen and (max-width: 1050px) {
  .lsp {
    margin-left: 15%;
  }
  .lsp2 {
    margin-left: 18%;
  }
  .opsirnije-ponuda {
    margin-left: -140% !important;
  }
  .cijena-smjestaj {
    margin-left: -40%;
    margin-top: 10%;
  }
  .opsirnije-ponuda-div {
    margin-top: 15%;
    margin-bottom: 15%;
  }
}
