.vizija {
  margin-top: 3%;
  font-size: xxx-large;
  font-weight: 400;
  font-family: Merriweather;
}

@media only screen and (max-width: 500px) {
  .vizija {
    margin-top: 9%;
    font-size: xx-large;
    font-weight: 400;
  }
}
