.kontakt-info-tekst{
    display: inline;
    
}

.kontakt-dimenzije{
    text-align: left;
    width: 347px;
    margin-left: 51%;
    margin-top: 10%;
}

.red-kontakt{
    margin-bottom: 3%;
}

.siva-slova{
    color: gray;
    justify-content: space-between;
}

.kontakt-dimenzije > .vizija{
    margin-bottom: 10%;
}

.kontakt-info-tekst > .nasa-vizija{
    padding-left: 0;
    margin-left: 0%;
}

.poruka{
    width: 70%;
    font-size: large;
    resize: none;
    padding: 12px 20px;
    margin-top: 1%;
    border:1.5px solid gray;
}

.kontakt-forma{
    margin-top: 9%;
    margin-bottom:auto;
    text-align: right;
    margin-bottom: 15%;
    margin-right: -70%;
}

@media only screen and (max-width: 945px) {
   .kontakt-dimenzije{
       margin-left: 5%;
   }

   .kontakt-forma{
       margin-right: -75%;
   }
 
}

@media only screen and (max-width: 767px) {
   
    .kontakt-dimenzije{
        margin: auto;
        padding-left: 5%;
        padding-top: 5%;
        padding-bottom: 10%;
        
    }

    .kontakt-forma{
        margin: auto;
        padding-left: 0;
        margin-right: 3%;
        margin-left: -38%;
        padding-bottom: 15%;
    }
 
}


