.nasa-vizija {
  margin-top: 2%;
  margin-bottom: 5%;
  padding: 0 20%;
}

@media only screen and (max-width: 800px) {
  .nasa-vizija {
    padding: 1.5%;
  }
}

@media only screen and (max-width: 500px) {
  .nasa-vizija {
    font-size: large;
    font-weight: 300;
  }
}
