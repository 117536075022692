/* Clear floats */
.row-smjestaj:after {
    content: "";
    display: table;
    clear: both
  }
  
  /* 2/3 column */
  .column-55 {
    float: left;
    width: 55%;
    overflow: hidden;
    
  }
  .book::before{
    display: none;
  }
  
  /* 1/3 column */
  .column-45 {
    float: left;
    width: 45%;
    background-color:rgb(250, 249, 249);
  }

  .container4{
    margin: 0 auto;
    margin-bottom: 4%;
}

@media screen and (max-width: 1535px) {
    .column-55,
    .column-45 {
      width: 100%;
      text-align: center;
    }
}