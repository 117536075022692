.sve{
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin-right: 0;
  width: 65%;
  margin-left: -10%;
  margin-right: -20%;
  position: relative;
  padding-right: 0;
  left: -5%;
}