.instagram{
    margin-top: 0.6%;
    margin-left: 35%;
  }
  
  .insta{
    font-size: large;
    color: #fff;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  }


  .insta2{
    color: white;
    font-size: large;
    margin-left: -18%;
    margin-bottom: 9.5%;
    transition: color ease-in-out 0.3s;
  }

  .insta2:hover{
    color: #40E0D0;
  }