
.fa-mobile-phone{
   font-size:36px;
}

.zaglavlje{
   margin-bottom: 1%;
}
.logo{
   margin-left: -1%;
   margin-top: 0.2%;
   transition: all ease-in-out;
}

.logo:hover{
   cursor: pointer;
}


@media only screen and (max-width: 939px) {
   
   .logo{
      margin-left: -13%;
      margin-top: -0.7%;
      
   }

}
